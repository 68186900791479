import React from 'react';
import './loading.scss';
import Logo from './logo';

const Loading = () => {
    return (
        <div className="page loading">
            <div className="nav-interstitial">
                <div className="navigation">
                    <div className="nav-logo">
                        <span className="nav-logo_desktop">
                            <Logo />
                        </span>
                        <span className="nav-logo_mobile">
                            <Logo icon />
                        </span>
                    </div>
                    <div className="nav-item"></div>
                    <div className="nav-item"></div>
                    <div className="nav-item"></div>
                    <div className="nav-list-container">
                        <div className="nav-list">
                            <hr />
                            <div className="nav-item nav-logout"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content"></div>
        </div>
    );
};

export default Loading;
