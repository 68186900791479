import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'reactfire';
import Logo from './logo';
import './navigation.scss';

const Navigation = () => {
    const auth = useAuth();

    const logout = () => {
        auth.signOut();
    };

    return (
        <div className="navigation-spacer">
            <div className="navigation-container">
                <div className="navigation">
                    <div className="nav-logo">
                        <span className="nav-logo_desktop">
                            <Logo />
                        </span>
                        <span className="nav-logo_mobile">
                            <Logo icon />
                        </span>
                    </div>
                    <NavLink
                        className="nav-item"
                        activeClassName="active"
                        to="/dashboard"
                    >
                        <i className="ci-dashboard nav-icon" />
                        <span className="nav-label">Dashboard</span>
                    </NavLink>
                    <NavLink
                        className="nav-item"
                        activeClassName="active"
                        to="/budget"
                    >
                        <i className="ci-pie_chart_25 nav-icon" />
                        <span className="nav-label">Budget</span>
                    </NavLink>
                    <NavLink
                        className="nav-item"
                        activeClassName="active"
                        to="/transactions"
                    >
                        <i className="ci-credit_card nav-icon" />
                        <span className="nav-label">Transactions</span>
                    </NavLink>
                    <div className="nav-list-container">
                        <div className="nav-list">
                            <NavLink
                                className="nav-item"
                                activeClassName="active"
                                to="/settings"
                            >
                                <i className="ci-settings_filled nav-icon" />
                                <span className="nav-label">Settings</span>
                            </NavLink>
                            <hr />
                            <button
                                className="nav-item nav-logout"
                                onClick={logout}
                            >
                                <i className="ci-log_out nav-icon" />
                                <span className="nav-label">Log out</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navigation;
