import { SET_BUDGET } from './budget.types';

const reducer = (state = {}, action) => {
    switch (action.type) {
        case SET_BUDGET:
            return {
                ...state,
                budget: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
