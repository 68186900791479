import map from 'lodash/map';
import React, { useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import TransactionItem from '../transactionItem';
import TransactionForm from '../transactionForm';
import Spinner from '../spinner';
import './transactionTable.scss';

const TransactionTable = ({ transactions, adding = false, simple = false }) => {
    const stepSize = 20;
    const [limit, setLimit] = useState(stepSize);
    const [loading, setLoading] = useState(false);

    const loadMore = () => {
        setLoading(true);
        setLimit(limit + stepSize);
        setLoading(false);
    };

    const [infiniteScrollRef] = useInfiniteScroll({
        loading: loading,
        hasNextPage: transactions.length > limit,
        onLoadMore: loadMore,
        disabled: transactions.length <= limit,
    });

    const renderTransactions = () => {
        return map(transactions.slice(0, limit), (transaction) => {
            return (
                <TransactionItem
                    key={transaction.key}
                    transaction={transaction}
                    adding={adding}
                    simple={simple}
                />
            );
        });
    };

    const renderTransactionForm = () => {
        return adding ? <TransactionForm focusDatePicker /> : null;
    };

    const renderTableHeader = () => {
        const className = `table-header ${adding ? 'adding' : ''} ${
            simple ? 'simple' : ''
        }`;

        return (
            <div className={className}>
                <span>Date</span>
                <span>Amount</span>
                {!simple && <span>Category</span>}
                <span>Details</span>
            </div>
        );
    };

    const renderTable = () => {
        let content = (
            <>
                {renderTableHeader()}
                {renderTransactionForm()}
                {renderTransactions()}
            </>
        );

        if (transactions.length === 0) {
            content = (
                <div className="no-transactions">No matching transactions</div>
            );
        }

        return content;
    };

    return (
        <div className="transaction-table">
            {renderTable()}
            <div ref={infiniteScrollRef} className="load-more">
                {transactions.length > limit && <Spinner />}
            </div>
        </div>
    );
};

export default TransactionTable;
