import map from 'lodash/map';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import filter from 'lodash/filter';
import { sumByCategory, getExpenseTotal } from '../../utils/money';

export const getTransactionsList = (state) => {
    const children = map(state.transactions?.transactions, (child, key) => {
        return {
            ...child,
            key: key,
        };
    });

    return reverse(sortBy(children, (t) => moment(t.date, 'YYYY-MM-DD')));
};

export const getFilteredTransactionsList = (state, filters) => {
    const transactions = getTransactionsList(state);
    const { category, subcategory, fromDate, toDate } = filters;

    const appliedFilters = {};

    if (category !== 'all') {
        appliedFilters['category'] = category;
    }

    if (subcategory !== 'all') {
        appliedFilters['subcategory'] = subcategory;
    }

    let filteredTransactions = filter(transactions, appliedFilters);

    const momentFromDate = moment(fromDate, 'YYYY-MM-DD');

    if (fromDate && fromDate !== '' && momentFromDate.isValid()) {
        filteredTransactions = filter(filteredTransactions, (t) =>
            moment(t.date, 'YYYY-MM-DD').isSameOrAfter(momentFromDate)
        );
    }

    const momentToDate = moment(toDate, 'YYYY-MM-DD');

    if (toDate && toDate !== '' && momentToDate.isValid()) {
        filteredTransactions = filter(filteredTransactions, (t) =>
            moment(t.date, 'YYYY-MM-DD').isSameOrBefore(momentToDate)
        );
    }

    return filteredTransactions;
};

export const getStaleTransactions = (state) => {
    const staleThreshold = 120;
    const staleDate = moment()
        .subtract(staleThreshold, 'days')
        .format('YYYY-MM-DD');

    return getFilteredTransactionsList(state, {
        category: 'all',
        subcategory: 'all',
        fromDate: '',
        toDate: staleDate,
    });
};

export const getOldestTransactionDate = (state) => {
    const transactions = getTransactionsList(state);
    
    return transactions?.[transactions.length - 1]?.date;
}

export const getTransactionById = (state, id) => {
    return { ...state.transactions?.transactions?.[id], key: id };
};

export const getTransactionsForMonth = (state, month, year) => {
    return filter(state.transactions?.transactions, (t) => {
        const transactionMonth = moment(t.date, 'YYYY-MM-DD').month();
        const transactionYear = moment(t.date, 'YYYY-MM-DD').year();

        return transactionMonth === month && transactionYear === year;
    });
};

export const getIncomeForMonth = (state, month, year) => {
    const transactions = getTransactionsForMonth(state, month, year);

    return sumByCategory(transactions, 'Income');
};

export const getExpensesForMonth = (state, month, year) => {
    const transactions = getTransactionsForMonth(state, month, year);

    return getExpenseTotal(transactions) * -1;
};
