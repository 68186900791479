import React, { useRef, useEffect } from 'react';
import find from 'lodash/find';
import { categoryIsIncome } from '../../utils/money';
import { addTransaction, editTransaction } from '../../utils/api';
import Button from '../button';
import { useDatabase } from 'reactfire';
import map from 'lodash/map';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import union from 'lodash/union';
import './transactionForm.scss';

const TransactionForm = ({
    budget,
    budgetCategories,
    focusDatePicker,
    setAdding,
    toggleEditOff,
    editMode,
    transaction,
}) => {
    const database = useDatabase();

    const datepickerRef = useRef(null);
    const amountRef = useRef(null);
    const subcategoryRef = useRef(null);
    const notesRef = useRef(null);

    useEffect(() => {
        if (focusDatePicker) {
            datepickerRef.current.focus();
        }
    }, [focusDatePicker]);

    const pushTransaction = (event) => {
        event.preventDefault();

        const date = datepickerRef?.current?.value;
        const notes = notesRef?.current?.value;
        const numberAmount = amountRef?.current?.value;
        const subcategory = subcategoryRef?.current?.value;

        const formIsComplete =
            subcategory && subcategory !== 'Category' && numberAmount && date;

        const category = find(
            budget,
            (b) => b.subcategory === subcategory
        ).category;
        const amount = categoryIsIncome(budget, subcategory)
            ? numberAmount
            : -numberAmount;

        if (formIsComplete) {
            const newTransaction = {
                subcategory,
                date,
                notes,
                category,
                amount,
            };

            if (editMode) {
                editTransaction(
                    transaction?.key,
                    newTransaction,
                    database,
                    () => {
                        if (toggleEditOff) {
                            toggleEditOff();
                        }
                    }
                );
            } else {
                addTransaction(newTransaction, database, () => {
                    subcategoryRef.current.value = 'Category';
                    amountRef.current.value = '';
                    datepickerRef.current.value = '';
                    notesRef.current.value = '';
                    datepickerRef.current.focus();
                });
            }
        } else {
            if (!date) {
                datepickerRef.current.focus();
            } else if (!numberAmount) {
                amountRef.current.focus();
            } else if (!subcategory || subcategory === 'Category') {
                subcategoryRef.current.focus();
            }
        }
    };

    const cancel = (event) => {
        event.preventDefault();

        if (editMode) {
            if (toggleEditOff) {
                toggleEditOff();
            }
        } else {
            if (setAdding) {
                setAdding(false);
            }
        }
    };

    const renderBudgetOptions = () => {
        return map(budgetCategories, (cat) => {
            const subcategories = sortBy(
                filter(budget, (b) => b.category === cat),
                'subcategory'
            );

            const otherItem = remove(subcategories, (s) =>
                includes(s.subcategory, 'Other')
            );

            const sortedSubcategories = union(subcategories, otherItem);

            return (
                <optgroup key={cat} label={cat}>
                    {map(sortedSubcategories, (b) => {
                        return (
                            <option key={b.subcategory}>{b.subcategory}</option>
                        );
                    })}
                </optgroup>
            );
        });
    };

    const getDefaultValue = (key, defaultValue = undefined) => {
        let value = transaction?.[key];

        if (key === 'amount') {
            value = -value;
        }

        return editMode ? value : defaultValue;
    };

    return (
        <form
            className={`transaction-form ${
                editMode ? 'edit-mode' : 'add-mode'
            }`}
        >
            <span className="date">
                <input
                    placeholder="MM/DD/YY"
                    type="date"
                    ref={datepickerRef}
                    defaultValue={getDefaultValue('date')}
                ></input>
            </span>
            <span className="price">
                $
                <input
                    ref={amountRef}
                    type="number"
                    defaultValue={getDefaultValue('amount')}
                    placeholder="0.00"
                ></input>
            </span>
            <span className="category">
                <select
                    ref={subcategoryRef}
                    defaultValue={getDefaultValue(
                        'subcategory',
                        'Select a category'
                    )}
                >
                    <option disabled>Select a category</option>
                    {renderBudgetOptions()}
                </select>
            </span>
            <span className="notes">
                <input
                    placeholder="Example: Trader Joe's"
                    ref={notesRef}
                    defaultValue={getDefaultValue('notes')}
                    maxLength={50}
                ></input>

                <span className="controls">
                    <Button
                        type="submit"
                        buttonType="primary-small"
                        onClick={pushTransaction}
                    >
                        Save
                    </Button>
                    <Button buttonType="secondary-small" onClick={cancel}>
                        Cancel
                    </Button>
                </span>
            </span>
        </form>
    );
};

export default TransactionForm;
