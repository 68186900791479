import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PageWrapper from '../../components/pageWrapper';
import OverSpending from '../../components/overSpending';
import BudgetOverview from '../../components/budgetOverview';
import BudgetExpenseCategories from '../../components/budgetExpenseCategories';
import { useLocation } from 'react-router-dom';
import './dashboard.scss';
import MonthSelector from '../../components/monthSelector';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Dashboard = () => {
    const query = useQuery();

    useEffect(() => {
        document.title = 'Dashboard - Piglet';
    }, []);

    const getTimePeriod = () => {
        const value = query.get('m');
        const momentValue = value ? moment(value, 'YYYY-MM') : moment();

        return {
            month: momentValue.month(),
            year: momentValue.year(),
        };
    };

    const [timePeriod, setTimePeriod] = useState(getTimePeriod());

    return (
        <PageWrapper>
            <div className="dashboard">
                <div className="header">
                    <div className="header-left">
                        <h1>Dashboard</h1>
                    </div>
                    <div className="header-right">
                        <MonthSelector
                            timePeriod={timePeriod}
                            setTimePeriod={setTimePeriod}
                            pathName="dashboard"
                        />
                    </div>
                </div>
                <div className="card-grid">
                    <div className="overview">
                        <BudgetOverview timePeriod={timePeriod} />
                    </div>
                    <div className="overspend">
                        <OverSpending timePeriod={timePeriod} />
                    </div>
                    <div className="categories">
                        <BudgetExpenseCategories timePeriod={timePeriod} />
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};

export default Dashboard;
