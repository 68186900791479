import React from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';
import './transactionFilters.scss';
import Button from '../button';

const TransactionFilters = ({
    budget,
    budgetCategories,
    filters,
    setTransactionFilters,
}) => {
    const ALL_CATEGORIES = 'all';
    const ALL_SUBCATEGORIES = 'all';

    const updateTransactionFilters = (type, value) => {
        setTransactionFilters({
            ...filters,
            [type]: value,
        });
    };

    const updateCategory = (value) => {
        let newSubcategory = filters?.subcategory;

        if (
            filters?.subcategory !== ALL_SUBCATEGORIES &&
            value !== ALL_CATEGORIES &&
            find(budget, (b) => b.subcategory === filters?.subcategory)
                ?.category !== value
        ) {
            newSubcategory = ALL_SUBCATEGORIES;
        }

        setTransactionFilters({
            ...filters,
            category: value,
            subcategory: newSubcategory,
        });
    };

    const renderCategoryFilter = () => {
        const className = `filter-group ${
            filters?.category !== ALL_CATEGORIES ? 'active' : ''
        }`;

        return (
            <span className={className}>
                <label htmlFor="categoryFilter">Category</label>
                <select
                    name="categoryFilter"
                    value={filters?.category}
                    onChange={(e) => {
                        updateCategory(e.target.value);
                    }}
                >
                    <option value={ALL_CATEGORIES}>All Categories</option>
                    {map(budgetCategories, (cat) => (
                        <option key={cat}>{cat}</option>
                    ))}
                </select>
            </span>
        );
    };

    const getSubcategoryOptionsForCategory = () => {
        let options = [];

        if (filters?.category !== ALL_CATEGORIES) {
            options = map(
                filter(budget, (b) => b.category === filters?.category),
                (b) => {
                    return <option key={b.subcategory}>{b.subcategory}</option>;
                }
            );
        } else {
            options = map(budgetCategories, (cat) => {
                return (
                    <optgroup key={cat} label={cat}>
                        {map(
                            filter(budget, (b) => b.category === cat),
                            (b) => {
                                return (
                                    <option key={b.subcategory}>
                                        {b.subcategory}
                                    </option>
                                );
                            }
                        )}
                    </optgroup>
                );
            });
        }

        return options;
    };

    const renderSubcategoryFilter = () => {
        const className = `filter-group ${
            filters?.subcategory !== ALL_SUBCATEGORIES ? 'active' : ''
        }`;

        return (
            <span className={className}>
                <label htmlFor="subcategoryFilter">Subcategory</label>
                <select
                    name="subcategoryFilter"
                    value={filters?.subcategory}
                    onChange={(e) =>
                        updateTransactionFilters('subcategory', e.target.value)
                    }
                >
                    <option value={ALL_SUBCATEGORIES}>
                        All{' '}
                        {filters?.category === ALL_CATEGORIES
                            ? 'Subcategories'
                            : filters?.category}
                    </option>
                    {getSubcategoryOptionsForCategory()}
                </select>
            </span>
        );
    };

    const renderDateFilters = () => {
        const fromDateClassName = `filter-group ${
            filters?.fromDate ? 'active' : ''
        }`;
        const toDateClassName = `filter-group ${
            filters?.toDate ? 'active' : ''
        }`;
        return (
            <>
                <span className={fromDateClassName}>
                    <label htmlFor="fromDate">From</label>
                    <input
                        name="fromDate"
                        placeholder="MM/DD/YY"
                        type="date"
                        onChange={(e) =>
                            updateTransactionFilters('fromDate', e.target.value)
                        }
                        value={filters?.fromDate}
                    ></input>
                </span>
                <span className={toDateClassName}>
                    <label htmlFor="toDate">To</label>
                    <input
                        name="toDate"
                        placeholder="MM/DD/YY"
                        type="date"
                        onChange={(e) =>
                            updateTransactionFilters('toDate', e.target.value)
                        }
                        value={filters?.toDate}
                    ></input>
                </span>
            </>
        );
    };

    const clearFilters = () => {
        setTransactionFilters({
            category: 'all',
            subcategory: 'all',
            fromDate: '',
            toDate: '',
        });
    };

    const renderClearButton = () => {
        const { category, subcategory, fromDate, toDate } = filters;
        const filtersApplied =
            category !== 'all' || subcategory !== 'all' || fromDate || toDate;

        return (
            <Button
                buttonType="secondary-small"
                onClick={clearFilters}
                disabled={!filtersApplied}
            >
                Clear
            </Button>
        );
    };

    return (
        <div className="transaction-filters">
            {renderCategoryFilter()}
            {renderSubcategoryFilter()}
            {renderDateFilters()}
            {renderClearButton()}
        </div>
    );
};

export default TransactionFilters;
