import React, { useState, useEffect, useRef } from 'react';
import { formatCurrency } from '../../utils/money';
import moment from 'moment';
import { deleteTransaction } from '../../utils/api';
import { useDatabase } from 'reactfire';
import './transactionItem.scss';
import TransactionForm from '../transactionForm';

const TransactionItem = ({ transaction, adding, simple }) => {
    const { amount, key, date, subcategory, category, notes } = transaction;

    const database = useDatabase();
    const isIncome = parseFloat(amount) > 0;
    const formattedDate = moment(date, 'YYYY-MM-DD').format('MMM D, YYYY');
    const cost = (isIncome ? '+' : '') + formatCurrency(amount);
    const formattedNotes = notes ? notes : '-';
    const popupRef = useRef(null);
    const transactionRef = useRef(null);

    const [showActions, setShowActions] = useState(false);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowActions(false);
            }

            if (
                transactionRef.current &&
                !transactionRef.current.contains(event.target)
            ) {
                setEditMode(false);
                setShowActions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef, transactionRef]);

    const renderActionsPopup = () => {
        return showActions ? (
            <ul ref={popupRef} className="actions-popup">
                <li>
                    <button
                        className="edit-button"
                        onClick={() => setEditMode(true)}
                    >
                        Edit Transaction
                    </button>
                </li>
                <li>
                    <button
                        className="delete-button"
                        onClick={() => deleteTransaction(key, database)}
                    >
                        Delete Transaction
                    </button>
                </li>
            </ul>
        ) : null;
    };

    const renderTransaction = () => {
        const categoryName = category
            .replace(/ /g, '-')
            .replace(/\//g, '-')
            .toLowerCase();

        return (
            <div
                className={`transaction-table-item${
                    simple ? ' transaction-table-item_simple' : ''
                }`}
                key={key}
            >
                <span className="date">{formattedDate}</span>
                <span className={`price ${isIncome ? 'income' : ''}`}>
                    {cost}
                </span>
                <span className="subcategory">
                    <span>
                        {subcategory}
                        <span className="category">
                            <span
                                className={`category_indicator category_indicator_${categoryName}`}
                            ></span>
                            {category}
                        </span>
                    </span>
                </span>
                <span className={`notes ${notes ? '' : 'notes_empty'}`}>
                    {formattedNotes}
                </span>
                {!adding && !simple && (
                    <>
                        <button
                            className="actions"
                            onClick={() => setShowActions(true)}
                        >
                            <i className="ci-more_horizontal" />
                        </button>
                        {renderActionsPopup()}
                    </>
                )}
            </div>
        );
    };

    const toggleEditOff = () => {
        setEditMode(false);
        setShowActions(false);
    };

    return (
        <span ref={transactionRef}>
            {editMode & !adding ? (
                <TransactionForm
                    editMode
                    toggleEditOff={toggleEditOff}
                    transaction={transaction}
                />
            ) : (
                renderTransaction()
            )}
        </span>
    );
};

export default TransactionItem;
