import Transactions from './transactions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAdding } from '../../modules/transactionsPage/transactionsPage.actions';
import {
    getAddMode,
    getNumSelectedFilters,
} from '../../modules/transactionsPage/transactionsPage.selectors';

const mapStateToProps = (state) => {
    return {
        adding: getAddMode(state),
        numSelectedFilters: getNumSelectedFilters(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setAdding }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
