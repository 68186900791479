import React from 'react';

const Logo = ({ icon = false }) => {
    if (icon) {
        return (
            <svg
                viewBox="0 0 192 135"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M96 134.408C80.255 134.604 64.6723 131.206 50.4384 124.472C39.405 119.089 29.4974 111.653 21.2448 102.565C12.5033 93.1676 5.62051 82.1996 0.96 70.2418L0 67.2082L1.008 64.1746C5.67187 52.2264 12.5399 41.2618 21.2544 31.8512C29.5041 22.763 39.4084 15.3283 50.4384 9.944C64.6724 3.21046 80.255 -0.187743 96 0.00800111C111.745 -0.187359 127.328 3.21075 141.562 9.944C152.595 15.327 162.503 22.7618 170.755 31.8512C179.513 41.2357 186.398 52.2063 191.04 64.1746L192 67.2082L190.992 70.2418C175.931 109.446 137.992 135.073 96 134.408ZM84.0132 67.2113C84.0132 79.5844 73.9828 89.6148 61.6097 89.6148C49.2366 89.6148 39.2062 79.5844 39.2062 67.2113C39.2062 54.8382 49.2366 44.8078 61.6097 44.8078C73.9828 44.8078 84.0132 54.8382 84.0132 67.2113ZM128.82 89.6148C141.193 89.6148 151.224 79.5844 151.224 67.2113C151.224 54.8382 141.193 44.8078 128.82 44.8078C116.447 44.8078 106.417 54.8382 106.417 67.2113C106.417 79.5844 116.447 89.6148 128.82 89.6148Z"
                    fill="#252467"
                />
            </svg>
        );
    }
    return (
        <svg
            viewBox="0 0 136 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7118 28.7977C13.1349 28.8298 10.5846 28.2736 8.255 27.1716C6.44923 26.2904 4.8277 25.0736 3.47703 23.5861C2.04636 22.0481 0.919882 20.253 0.157118 18.2959L0 17.7994L0.164974 17.3029C0.928287 15.3474 2.05234 13.5529 3.4786 12.0127C4.82879 10.5253 6.44978 9.30851 8.255 8.42729C10.5846 7.32525 13.1349 6.76908 15.7118 6.80111C18.2887 6.76914 20.8391 7.32529 23.1687 8.42729C24.9745 9.3083 26.5961 10.5251 27.9467 12.0127C29.3801 13.5486 30.5069 15.3441 31.2666 17.3029L31.4237 17.7994L31.2587 18.2959C28.7938 24.7123 22.5845 28.9066 15.7118 28.7977ZM13.75 17.7999C13.75 19.8249 12.1084 21.4665 10.0833 21.4665C8.05827 21.4665 6.41665 19.8249 6.41665 17.7999C6.41665 15.7748 8.05827 14.1332 10.0833 14.1332C12.1084 14.1332 13.75 15.7748 13.75 17.7999ZM21.0833 21.4665C23.1084 21.4665 24.75 19.8249 24.75 17.7999C24.75 15.7748 23.1084 14.1332 21.0833 14.1332C19.0583 14.1332 17.4166 15.7748 17.4166 17.7999C17.4166 19.8249 19.0583 21.4665 21.0833 21.4665Z"
                fill="#252467"
            />
            <path
                d="M39.6 37.6V8.96H44.16L44.72 11.8C45.36 10.92 46.2 10.1467 47.24 9.48C48.3066 8.81333 49.68 8.48 51.36 8.48C53.2266 8.48 54.8933 8.93333 56.36 9.84C57.8266 10.7467 58.9866 11.9867 59.84 13.56C60.6933 15.1333 61.12 16.92 61.12 18.92C61.12 20.92 60.6933 22.7067 59.84 24.28C58.9866 25.8267 57.8266 27.0533 56.36 27.96C54.8933 28.84 53.2266 29.28 51.36 29.28C49.8666 29.28 48.56 29 47.44 28.44C46.32 27.88 45.4133 27.0933 44.72 26.08V37.6H39.6ZM50.28 24.8C51.9066 24.8 53.2533 24.2533 54.32 23.16C55.3866 22.0667 55.92 20.6533 55.92 18.92C55.92 17.1867 55.3866 15.76 54.32 14.64C53.2533 13.52 51.9066 12.96 50.28 12.96C48.6266 12.96 47.2666 13.52 46.2 14.64C45.16 15.7333 44.64 17.1467 44.64 18.88C44.64 20.6133 45.16 22.04 46.2 23.16C47.2666 24.2533 48.6266 24.8 50.28 24.8Z"
                fill="#252467"
            />
            <path
                d="M66.4537 5.88C65.5204 5.88 64.7471 5.6 64.1337 5.04C63.5471 4.48 63.2537 3.77333 63.2537 2.92C63.2537 2.06667 63.5471 1.37333 64.1337 0.839999C64.7471 0.28 65.5204 0 66.4537 0C67.3871 0 68.1471 0.28 68.7337 0.839999C69.3471 1.37333 69.6537 2.06667 69.6537 2.92C69.6537 3.77333 69.3471 4.48 68.7337 5.04C68.1471 5.6 67.3871 5.88 66.4537 5.88ZM63.8937 28.8V8.96H69.0137V28.8H63.8937Z"
                fill="#252467"
            />
            <path
                d="M80.7578 22.88C79.7978 22.88 78.9045 22.7733 78.0778 22.56L76.5978 24.04C77.0511 24.28 77.6645 24.48 78.4378 24.64C79.2111 24.8 80.4645 24.96 82.1978 25.12C84.8378 25.36 86.7578 25.9867 87.9578 27C89.1578 28.0133 89.7578 29.4133 89.7578 31.2C89.7578 32.3733 89.4378 33.48 88.7978 34.52C88.1578 35.5867 87.1711 36.44 85.8378 37.08C84.5045 37.7467 82.7978 38.08 80.7178 38.08C77.8911 38.08 75.6111 37.5467 73.8778 36.48C72.1445 35.44 71.2778 33.8667 71.2778 31.76C71.2778 29.9733 72.1445 28.4267 73.8778 27.12C73.3445 26.88 72.8778 26.6267 72.4778 26.36C72.1045 26.0933 71.7711 25.8133 71.4778 25.52V24.6L74.9578 20.92C73.4111 19.56 72.6378 17.8133 72.6378 15.68C72.6378 14.3467 72.9578 13.1333 73.5978 12.04C74.2645 10.9467 75.1978 10.08 76.3978 9.44C77.5978 8.8 79.0511 8.48 80.7578 8.48C81.8778 8.48 82.9178 8.64 83.8778 8.96H91.3978V12.08L87.9978 12.32C88.5311 13.3333 88.7978 14.4533 88.7978 15.68C88.7978 17.0133 88.4778 18.2267 87.8378 19.32C87.1978 20.4133 86.2645 21.28 85.0378 21.92C83.8378 22.56 82.4111 22.88 80.7578 22.88ZM80.7578 18.96C81.7978 18.96 82.6511 18.68 83.3178 18.12C84.0111 17.56 84.3578 16.76 84.3578 15.72C84.3578 14.68 84.0111 13.88 83.3178 13.32C82.6511 12.76 81.7978 12.48 80.7578 12.48C79.6645 12.48 78.7845 12.76 78.1178 13.32C77.4511 13.88 77.1178 14.68 77.1178 15.72C77.1178 16.76 77.4511 17.56 78.1178 18.12C78.7845 18.68 79.6645 18.96 80.7578 18.96ZM75.9578 31.24C75.9578 32.2267 76.4111 32.96 77.3178 33.44C78.2511 33.9467 79.3845 34.2 80.7178 34.2C81.9978 34.2 83.0378 33.9333 83.8378 33.4C84.6378 32.8933 85.0378 32.2 85.0378 31.32C85.0378 30.6 84.7711 30 84.2378 29.52C83.7311 29.04 82.7045 28.7467 81.1578 28.64C80.0645 28.56 79.0511 28.44 78.1178 28.28C77.3445 28.7067 76.7845 29.1733 76.4378 29.68C76.1178 30.1867 75.9578 30.7067 75.9578 31.24Z"
                fill="#252467"
            />
            <path d="M93.1312 28.8V0H98.2512V28.8H93.1312Z" fill="#252467" />
            <path
                d="M111.123 29.28C109.123 29.28 107.349 28.8533 105.803 28C104.256 27.1467 103.043 25.9467 102.163 24.4C101.283 22.8533 100.843 21.0667 100.843 19.04C100.843 16.9867 101.269 15.16 102.123 13.56C103.003 11.96 104.203 10.72 105.723 9.84C107.269 8.93333 109.083 8.48 111.163 8.48C113.109 8.48 114.829 8.90667 116.323 9.76C117.816 10.6133 118.976 11.7867 119.803 13.28C120.656 14.7467 121.083 16.3867 121.083 18.2C121.083 18.4933 121.069 18.8 121.043 19.12C121.043 19.44 121.029 19.7733 121.003 20.12H105.923C106.029 21.6667 106.563 22.88 107.523 23.76C108.509 24.64 109.696 25.08 111.083 25.08C112.123 25.08 112.989 24.8533 113.683 24.4C114.403 23.92 114.936 23.3067 115.283 22.56H120.483C120.109 23.8133 119.483 24.96 118.603 26C117.749 27.0133 116.683 27.8133 115.403 28.4C114.149 28.9867 112.723 29.28 111.123 29.28ZM111.163 12.64C109.909 12.64 108.803 13 107.843 13.72C106.883 14.4133 106.269 15.48 106.003 16.92H115.883C115.803 15.6133 115.323 14.5733 114.443 13.8C113.563 13.0267 112.469 12.64 111.163 12.64Z"
                fill="#252467"
            />
            <path
                d="M131.877 28.8C129.797 28.8 128.131 28.2933 126.877 27.28C125.624 26.2667 124.997 24.4667 124.997 21.88V13.24H121.597V8.96H124.997L125.597 3.64H130.117V8.96H135.477V13.24H130.117V21.92C130.117 22.88 130.317 23.5467 130.717 23.92C131.144 24.2667 131.864 24.44 132.877 24.44H135.357V28.8H131.877Z"
                fill="#252467"
            />
        </svg>
    );
};

export default Logo;
