import BudgetExpenseCategories from './budgetExpenseCategories';
import { connect } from 'react-redux';
import { getTransactionsForMonth } from '../../modules/transactions/transactions.selectors';
import {
    getBudget,
    getBudgetExpenseCategories,
} from '../../modules/budget/budget.selectors';

const mapStateToProps = (state, ownProps) => {
    const currentMonth = ownProps?.timePeriod?.month;
    const currentYear = ownProps?.timePeriod?.year;

    return {
        transactions: getTransactionsForMonth(state, currentMonth, currentYear),
        budget: getBudget(state),
        expenseCategories: getBudgetExpenseCategories(state),
    };
};

export default connect(mapStateToProps, null)(BudgetExpenseCategories);
