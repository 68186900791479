import React, { useState } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import {
    formatCurrency,
    sumBySubcategory,
    categoryIsIncome,
    categoryIsFinance,
} from '../../utils/money';
import SpendingModal from '../spendingModal';
import './budgetSheet.scss';

const BudgetSheet = ({ budget, transactions, timePeriod }) => {
    const [spendingModalOpen, setSpendingModalOpen] = useState(false);
    const [spendingModalData, setSpendingModalData] = useState({
        filters: {
            category: 'all',
            fromDate: '',
            subcategory: 'all',
            toDate: '',
        },
    });

    const openSpendingModal = (filters, monthlyLimit, amountSpent) => {
        setSpendingModalData({ monthlyLimit, amountSpent, filters });
        setSpendingModalOpen(true);
    };

    const closeSpendingModal = () => {
        setSpendingModalData({
            filters: {
                category: 'all',
                fromDate: '',
                subcategory: 'all',
                toDate: '',
            },
        });
        setSpendingModalOpen(false);
    };

    const budgetSheet = map(
        sortBy(budget, ['category', 'subcategory']),
        (budgetItem, id) => {
            const { subcategory, amount, category } = budgetItem;
            let actual = sumBySubcategory(transactions, subcategory);
            actual = actual < 0 ? actual * -1 : actual;
            const projected = amount;
            const over = actual > projected;
            const income =
                categoryIsIncome(budget, subcategory) ||
                categoryIsFinance(budget, subcategory);

            const categoryClassName = category
                .replace(/ /g, '-')
                .replace(/\//g, '-')
                .toLowerCase();

            const getTransactionFilters = () => {
                const date = `${timePeriod.month + 1}-${timePeriod.year}`;

                const fromDate = moment(date, 'MM-YYYY')
                    .startOf('month')
                    .format('YYYY-MM-DD');

                const toDate = moment(date, 'MM-YYYY')
                    .endOf('month')
                    .format('YYYY-MM-DD');

                return {
                    category,
                    subcategory,
                    fromDate,
                    toDate,
                };
            };

            const timePeriodLabel = moment(timePeriod).format(`MMMM YYYY`);

            return actual > 0 ? (
                <button
                    className="table-item"
                    key={id}
                    onClick={() =>
                        openSpendingModal(
                            getTransactionFilters(),
                            projected,
                            actual
                        )
                    }
                    title={`View "${budgetItem.subcategory}" spending for ${timePeriodLabel}`}
                >
                    <span className="subcategory">
                        {budgetItem.subcategory}
                    </span>
                    <span>
                        <span
                            className={`category category_${categoryClassName}`}
                        >
                            {budgetItem.category}
                        </span>
                    </span>
                    <span className="projected">
                        {formatCurrency(projected, true)}
                    </span>
                    <span
                        className={`actual ${over ? 'actual_over' : ''} ${
                            income ? 'actual_income' : ''
                        }`}
                    >
                        {actual > 0 ? formatCurrency(actual) : '-'}
                    </span>
                </button>
            ) : null;
        }
    );

    return (
        <div className="budget-sheet">
            <div className="budget-table">
                <div className="table-header">
                    <span>Subcategory</span>
                    <span>Category</span>
                    <span>Monthly Limit</span>
                    <span>Amount Spent</span>
                </div>
                {budgetSheet}
            </div>

            {spendingModalOpen && (
                <SpendingModal
                    data={spendingModalData}
                    closeModal={closeSpendingModal}
                />
            )}
        </div>
    );
};

export default BudgetSheet;
