import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import { sumByCategory, getExpenseTotal } from '../../utils/money';

export const getBudget = (state) => {
    const children = map(state.budget?.budget, (child) => {
        return child;
    });

    return children;
};

export const getBudgetCategories = (state) => {
    const budget = getBudget(state);

    return map(uniqBy(budget, 'category'), 'category');
};

export const getProjectedIncome = (state) => {
    return sumByCategory(state.budget?.budget, 'Income');
};

export const getProjectedExpenses = (state) => {
    return getExpenseTotal(state.budget?.budget);
};

export const getBudgetExpenseCategories = (state) => {
    return filter(getBudgetCategories(state), (cat) => cat !== 'Income');
};
