import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';

const formatCurrency = (amount, noDecimal) => {
    const currency = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: 'USD',
    }).format(amount === -0 ? 0 : amount);

    if (noDecimal) {
        return currency.substr(0, currency.length - 3);
    } else {
        return currency;
    }
};

const categoryIsIncome = (list, category) => {
    const incomeCategories = map(
        filter(list, (li) => li.category === 'Income'),
        (li) => li.subcategory
    );

    return includes(incomeCategories, category);
};

const categoryIsFinance = (list, category) => {
    const financeCategories = map(
        filter(list, (li) => li.category === 'Finances'),
        (li) => li.subcategory
    );

    return includes(financeCategories, category);
};

const getAllCategories = (list) => {
    return map(
        uniqBy(list, (li) => li.category),
        (li) => li.category
    );
};

const sumByCategory = (list, category) => {
    return sumBy(
        filter(list, (li) => li.category === category),
        (li) => parseFloat(li.amount)
    );
};

const sumBySubcategory = (list, subcategory) => {
    return sumBy(
        filter(list, (li) => li.subcategory === subcategory),
        (li) => parseFloat(li.amount)
    );
};

const getExpenseTotal = (list) => {
    return sumBy(
        filter(list, (li) => li.category !== 'Income' && li.category !== 'Finances'),
        (li) => parseFloat(li.amount)
    );
};

export {
    formatCurrency,
    categoryIsIncome,
    categoryIsFinance,
    sumByCategory,
    sumBySubcategory,
    getExpenseTotal,
    getAllCategories,
};
