import PageWrapper from '../../components/pageWrapper';
import React from 'react';
import Button from '../../components/button';
import { deleteTransactions } from '../../utils/api';
import { useDatabase } from 'reactfire';
import moment from 'moment';
import './settings.scss';

const Settings = ({ staleTransactions, oldestTransactionDate }) => {
    const database = useDatabase();

    const renderPageHeader = () => {
        return (
            <div className="header">
                <h1>Settings</h1>
            </div>
        );
    };

    const getLastClearedDate = () => {
        const lastClearedDate = moment(oldestTransactionDate, 'YYYY-MM-DD').add(
            119,
            'days'
        );

        const difference = moment().diff(lastClearedDate, 'days');

        const datePhrasing = {
            0: 'today',
            1: 'one day ago',
            2: `${difference} days ago`,
        };

        return datePhrasing[difference > 2 ? 2 : difference];
    };

    const sweepStaleTransactions = () => {
        deleteTransactions(staleTransactions, database);
    };

    const renderStaleSweeper = () => {
        return (
            <div className="stale-sweeper">
                <h2>Transaction Data</h2>
                <hr />
                <h3>Remove old data</h3>
                <p>
                    This will delete all transaction and budget data older than
                    120 days.
                </p>
                <Button buttonType="danger" onClick={sweepStaleTransactions}>
                    <i className="ci-trash_full" />
                    Delete Old Transactions
                </Button>
                <p>Last cleared {getLastClearedDate()}</p>
            </div>
        );
    };

    return (
        <PageWrapper>
            <div className="settings">
                {renderPageHeader()}
                {renderStaleSweeper()}
            </div>
        </PageWrapper>
    );
};

export default Settings;
