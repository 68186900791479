import SpendingModal from './spendingModal';
import { connect } from 'react-redux';
import { getFilteredTransactionsList } from '../../modules/transactions/transactions.selectors';

const mapStateToProps = (state, ownProps) => {
    return {
        transactions: getFilteredTransactionsList(state, ownProps.data.filters),
    };
};

export default connect(mapStateToProps)(SpendingModal);
