import {
    SET_TRANSACTION_FILTERS,
    SET_ADDING,
} from './transactionsPage.types';

const reducer = (
    state = {
        filters: {
            category: 'all',
            subcategory: 'all',
            fromDate: '',
            toDate: '',
        },
        adding: false,
        editing: false,
    },
    action
) => {
    switch (action.type) {
        case SET_TRANSACTION_FILTERS:
            return {
                ...state,
                filters: action.payload,
            };
        case SET_ADDING:
            return {
                ...state,
                adding: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
