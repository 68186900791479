import forEach from 'lodash/forEach';

const addTransaction = (transaction, database, successCallback) => {
    const transactionRef = database.ref('transactions');

    const newTransactionKey = transactionRef.push().key;

    let updates = {
        [newTransactionKey]: { ...transaction },
    };

    transactionRef.update(updates, (error) => {
        if (error) {
            console.log(error);
        } else {
            if (successCallback) {
                successCallback();
            }
        }
    });
};

const editTransaction = (transactionId, updates, database, successCallback) => {
    if (transactionId) {
        const transactionRef = database.ref('transactions/' + transactionId);

        transactionRef.update(updates, (error) => {
            if (error) {
                console.log(error);
            } else {
                if (successCallback) {
                    successCallback();
                }
            }
        });
    }
};

const deleteTransaction = (transactionKey, database) => {
    database.ref('transactions/' + transactionKey).remove();
};

const deleteTransactions = (transactions, database) => {
    let updatedKeys = {};

    forEach(transactions, (transaction) => {
        updatedKeys = {
            ...updatedKeys,
            [transaction.key]: null,
        };
    });

    database.ref('transactions').update(updatedKeys);
};

export {
    addTransaction,
    editTransaction,
    deleteTransaction,
    deleteTransactions,
};
