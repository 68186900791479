import { SET_TRANSACTIONS } from './transactions.types';

const reducer = (state = {}, action) => {
    switch (action.type) {
        case SET_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload,
            };
    default:
            return state;
    }
};

export default reducer;
