import Settings from './settings';
import { connect } from 'react-redux';
import { getStaleTransactions, getOldestTransactionDate } from '../../modules/transactions/transactions.selectors';

const mapStateToProps = (state) => {
    return {
        staleTransactions: getStaleTransactions(state),
        oldestTransactionDate: getOldestTransactionDate(state)
    };
};

export default connect(mapStateToProps)(Settings);
