import BudgetOverview from './budgetOverview';
import { connect } from 'react-redux';
import {
    getIncomeForMonth,
    getExpensesForMonth,
} from '../../modules/transactions/transactions.selectors';
import {
    getProjectedIncome,
    getProjectedExpenses,
} from '../../modules/budget/budget.selectors';

const mapStateToProps = (state, ownProps) => {
    const currentMonth = ownProps?.timePeriod?.month;
    const currentYear = ownProps?.timePeriod?.year;

    return {
        income: getIncomeForMonth(state, currentMonth, currentYear),
        projectedIncome: getProjectedIncome(state),
        expenses: getExpensesForMonth(state, currentMonth, currentYear),
        projectedExpenses: getProjectedExpenses(state),
    };
};

export default connect(mapStateToProps, null)(BudgetOverview);
