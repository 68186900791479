import forEach from 'lodash/forEach';

export const getSelectedFilters = (state) => {
    return state.transactionsPage?.filters;
};

export const getNumSelectedFilters = (state) => {
    const filters = getSelectedFilters(state);
    let num = 0;
    
    forEach(filters, (filter) => {
        if (filter && filter !== 'all') {
            num++;
        }
    });

    return num;
};

export const getAddMode = (state) => {
    return state.transactionsPage?.adding;
};
