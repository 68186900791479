import TransactionTable from '../transactionTable/transactionTable';
import React from 'react';
import Modal from '../modal';
import { formatCurrency } from '../../utils/money';
import moment from 'moment';
import './spendingModal.scss';

const SpendingModal = ({ transactions, closeModal, data }) => {
    const { filters, monthlyLimit, amountSpent } = data;
    const timePeriodLabel = moment(filters.toDate).format(`MMMM YYYY`);
    const actual = formatCurrency(amountSpent, false);
    const projected = ` / ${formatCurrency(monthlyLimit, true)}`;
    let actualClass = 'spending-modal-overview--actual';

    if (filters.category === 'Income' && amountSpent > monthlyLimit) {
        actualClass += ' spending-modal-overview--actual_positive';
    } else if (amountSpent > monthlyLimit) {
        actualClass += ' spending-modal-overview--actual_negative';
    }

    const modalTitle = (
        <div className="spending-modal--title">
            <h1>
                {filters.subcategory}
                <span className="spending-modal--time-period">
                    {timePeriodLabel}
                </span>
            </h1>
            <span className="spending-modal-overview">
                <span className={actualClass}>{actual}</span>
                <span className="spending-modal-overview--projected">
                    {projected}
                </span>
            </span>
        </div>
    );

    return (
        <Modal closeModal={closeModal} modalTitle={modalTitle}>
            <div className="spending-modal">
                <TransactionTable
                    transactions={transactions}
                    adding={false}
                    simple
                />
            </div>
        </Modal>
    );
};

export default SpendingModal;
