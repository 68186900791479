import TransactionForm from './transactionForm';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    getBudget,
    getBudgetCategories,
} from '../../modules/budget/budget.selectors';
import { setAdding } from '../../modules/transactionsPage/transactionsPage.actions';

const mapStateToProps = (state) => {
    return {
        budget: getBudget(state),
        budgetCategories: getBudgetCategories(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setAdding }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionForm);
