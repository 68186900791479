import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import Transactions from './pages/transactions/transactions.container';
import Budget from './pages/budget/budget';
import Login from './pages/login';
import Dashboard from './pages/dashboard/dashboard';
import Settings from './pages/settings/settings.container';
import { useUser, useDatabase } from 'reactfire';
import { useDispatch } from 'react-redux';
import { setTransactions } from './modules/transactions/transactions.actions';
import { setBudget } from './modules/budget/budget.actions';
import './App.scss';

const App = () => {
    const user = useUser();
    const database = useDatabase();
    const dispatch = useDispatch();

    useEffect(() => {
        database.ref('transactions').on('value', function (snapshot) {
            dispatch(setTransactions(snapshot.val()));
        });
        database.ref('budget').on('value', function (snapshot) {
            dispatch(setBudget(snapshot.val()));
        });

        return () => {
            database.ref('transactions').off('value');
            database.ref('budget').off('value');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderRoutes = (location) => {
        return (
            <Switch location={location}>
                <Route
                    exact
                    path="/"
                    render={() => {
                        if (!user) {
                            return <Redirect to="/login" />;
                        } else {
                            return <Redirect to="/dashboard" />;
                        }
                    }}
                />
                <Route
                    exact
                    path="/dashboard"
                    render={() => {
                        window.scrollTo(0, 0);
                        return <Dashboard />;
                    }}
                />
                <Route
                    exact
                    path="/transactions"
                    render={() => {
                        window.scrollTo(0, 0);
                        return <Transactions />;
                    }}
                />
                <Route
                    exact
                    path="/budget"
                    render={() => {
                        window.scrollTo(0, 0);
                        return <Budget />;
                    }}
                />
                <Route
                    exact
                    path="/settings"
                    render={() => {
                        window.scrollTo(0, 0);
                        return <Settings />;
                    }}
                />
                <Route exact path="/login" component={Login} />
            </Switch>
        );
    };

    const validateAuthentication = () => {
        return !user ? <Redirect to="/login" /> : null;
    };

    return (
        <Router>
            <Route
                render={({ location }) => (
                    <>
                        {validateAuthentication()}
                        {renderRoutes(location)}
                    </>
                )}
            />
        </Router>
    );
};

export default App;
