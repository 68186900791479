import React from 'react';
import Card from '../../components/card';
import { formatCurrency } from '../../utils/money';
import './budgetOverview.scss';

const BudgetOverview = ({
    income,
    projectedIncome,
    expenses,
    projectedExpenses,
}) => {
    const renderOverviewItem = (label, priceA, priceB) => {
        const actual = formatCurrency(priceA, true);
        const projected = ` / ${formatCurrency(priceB, true)}`;
        let actualClass = 'budget-overview-item--actual';

        if (label === 'Income' && priceA > priceB) {
            actualClass += ' budget-overview-item--actual_positive';
        } else if (label === 'Expenses' && priceA > priceB) {
            actualClass += ' budget-overview-item--actual_negative';
        }

        return (
            <span className="budget-overview-item">
                <span className="budget-overview-item--label">{label}</span>
                <div>
                    <span className={actualClass}>{actual}</span>
                    <span className="budget-overview-item--projected">
                        {projected}
                    </span>
                </div>
            </span>
        );
    };

    const renderRemainingFunds = () => {
        return (
            <span className="overview-item overview-item--difference">
                <div>
                    <span
                        className={`budget-overview-item--actual ${
                            projectedExpenses - expenses > 0
                                ? 'budget-overview-item--actual_positive'
                                : 'budget-overview-item--actual_negative'
                        }`}
                    >
                        {projectedExpenses - expenses >= 0
                            ? formatCurrency(projectedExpenses - expenses, true)
                            : formatCurrency(
                                  (projectedExpenses - expenses) * -1,
                                  true
                              )}
                    </span>
                    <span className="budget-overview-item--projected">
                        {projectedExpenses - expenses >= 0
                            ? ' under budget'
                            : ' over budget'}
                    </span>
                </div>
            </span>
        );
    };

    return (
        <Card className="budget-overview">
            <span className="budget-overview--income">
                {renderOverviewItem('Income', income, projectedIncome)}
            </span>
            <span className="budget-overview--expenses">
                {renderOverviewItem('Expenses', expenses, projectedExpenses)}
                {renderRemainingFunds()}
            </span>
        </Card>
    );
};

export default BudgetOverview;
