import React from 'react';
import firebaseConfig from './firebaseConfig';
import { FirebaseAppProvider, SuspenseWithPerf } from 'reactfire';
import Loading from '../components/loading';

const FirebaseProvider = ({ children }) => {
    return (
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <SuspenseWithPerf fallback={<Loading />}>
                {children}
            </SuspenseWithPerf>
        </FirebaseAppProvider>
    );
};

export default FirebaseProvider;
