import React from 'react';
import './card.scss';

const Card = ({ children, className, ...allProps }) => {
    return (
        <div className={`card ${className ? className : ''}`} {...allProps}>
            {children}
        </div>
    );
};

export default Card;
