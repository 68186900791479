import BudgetSheet from './budgetSheet';
import { connect } from 'react-redux';
import { getTransactionsForMonth } from '../../modules/transactions/transactions.selectors';
import { getBudget } from '../../modules/budget/budget.selectors';

const mapStateToProps = (state, ownProps) => {
    const currentMonth = ownProps?.timePeriod?.month;
    const currentYear = ownProps?.timePeriod?.year;

    return {
        transactions: getTransactionsForMonth(state, currentMonth, currentYear),
        budget: getBudget(state),
    };
};

export default connect(mapStateToProps)(BudgetSheet);
