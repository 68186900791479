import TransactionTable from './transactionTable';
import { connect } from 'react-redux';
import { getFilteredTransactionsList } from '../../modules/transactions/transactions.selectors';
import {
    getSelectedFilters,
    getAddMode,
} from '../../modules/transactionsPage/transactionsPage.selectors';

const mapStateToProps = (state) => {
    const filters = getSelectedFilters(state);

    return {
        transactions: getFilteredTransactionsList(state, filters),
        adding: getAddMode(state),
    };
};

export default connect(mapStateToProps, null)(TransactionTable);
