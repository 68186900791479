import {
    SET_TRANSACTION_FILTERS,
    SET_ADDING,
} from './transactionsPage.types';

export const setTransactionFilters = (filters) => {
    return {
        type: SET_TRANSACTION_FILTERS,
        payload: filters,
    };
};

export const setAdding = (addMode) => {
    return {
        type: SET_ADDING,
        payload: addMode,
    };
};