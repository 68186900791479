import MonthSelector from './monthSelector';
import { connect } from 'react-redux';
import { getOldestTransactionDate } from '../../modules/transactions/transactions.selectors';

const mapStateToProps = (state) => {
    return {
        oldestDate: getOldestTransactionDate(state),
    };
};

export default connect(mapStateToProps)(MonthSelector);
