import React from 'react';
import Card from '../../components/card';
import {
    formatCurrency,
    sumBySubcategory,
    categoryIsIncome,
    categoryIsFinance,
} from '../../utils/money';
import map from 'lodash/map';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import './overSpending.scss';

const OverSpending = ({ budget, transactions }) => {
    const overBudgetList = sortBy(
        filter(budget, (budgetItem) => {
            const { subcategory, amount } = budgetItem;
            let actual = sumBySubcategory(transactions, subcategory);
            actual = actual < 0 ? actual * -1 : actual;
            const projected = amount;
            const over = actual > projected;
            const income =
                categoryIsIncome(budget, subcategory) ||
                categoryIsFinance(budget, subcategory);

            return over && !income;
        }),
        (budgetItem) => {
            const { subcategory, amount } = budgetItem;
            let actual = sumBySubcategory(transactions, subcategory);
            actual = actual < 0 ? actual * -1 : actual;
            const projected = amount;

            return projected - actual;
        }
    );

    const renderOverList = () => {
        return overBudgetList?.length > 0 ? (
            <div className="over-spending--list">
                {map(overBudgetList, (budgetItem) => {
                    const { subcategory, category, amount } = budgetItem;
                    let actual = sumBySubcategory(transactions, subcategory);
                    actual = actual < 0 ? actual * -1 : actual;
                    const projected = amount;

                    return (
                        <div className="over-spending--item">
                            <div className="over-spending--category">
                                {subcategory}
                                <span className="category">{category}</span>
                            </div>
                            <span className="over-spending--amount">
                                <span className="over-spending--spent">
                                    {formatCurrency(actual)}
                                </span>
                                /
                                <span className="over-spending--budget">
                                    {formatCurrency(projected, true)}
                                </span>
                            </span>
                        </div>
                    );
                })}
            </div>
        ) : (
            <span>Look at that, you're good on spending so far!</span>
        );
    };

    return (
        <Card className="over-spending">
            <div className="over-spending--header">
                <h2>Where you're over budget</h2>
                {renderOverList()}
            </div>
        </Card>
    );
};

export default OverSpending;
