import React from 'react';
import Navigation from './navigation';
import './pageWrapper.scss';

const PageWrapper = ({ children }) => {
    return (
        <div className="page">
            <Navigation />
            <div className="page-content">{children}</div>
        </div>
    );
};

export default PageWrapper;
