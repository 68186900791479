import React from 'react';
import Card from '../card';
import { formatCurrency, sumByCategory } from '../../utils/money';
import './budgetExpenseCategories.scss';

const BudgetExpenseCategories = ({
    expenseCategories,
    transactions,
    budget,
}) => {
    const renderIconForCategory = (category) => {
        switch (category) {
            case 'Finances':
                return <i className="ci-line_chart_up" />;
            case 'Transportation':
                return <i className="ci-map" />;
            case 'Home':
                return <i className="ci-home_outline" />;
            case 'Daily Living':
                return <i className="ci-coffee-togo" />;
            case 'Health':
                return <i className="ci-heart_outline" />;
            case 'Vacation/Holiday':
                return <i className="ci-tennis_match" />;
            default:
                return '';
        }
    };

    const renderCategorySums = () => {
        return expenseCategories.map((cat) => {
            let actual = sumByCategory(transactions, cat);
            actual = actual < 0 ? actual * -1 : actual;

            const projected = sumByCategory(budget, cat);
            const over = actual > projected;

            const categoryClassName = cat
                .replace(/ /g, '-')
                .replace(/\//g, '-')
                .toLowerCase();

            return (
                <span className="sum-item" key={cat}>
                    <span
                        className={`sum-item--icon sum-item--icon_${categoryClassName}`}
                    >
                        {renderIconForCategory(cat)}
                    </span>
                    <div className="sum-item--info">
                        <span className="sum-item--label">{cat}</span>
                        <div>
                            <span
                                className={`sum-item--actual ${
                                    over ? 'sum-item--actual_over' : ''
                                }`}
                            >
                                {formatCurrency(actual, true)}
                            </span>
                            <span className="sum-item--projected">
                                {` / ${formatCurrency(projected, true)}`}
                            </span>
                        </div>
                    </div>
                </span>
            );
        });
    };

    return (
        <Card className="budget-expense-categories">
            {renderCategorySums()}
        </Card>
    );
};

export default BudgetExpenseCategories;
