import React, { useRef, useEffect } from 'react';
import './modal.scss';

const useCloseOnOutside = (ref, closeModal) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, closeModal]);
};

const Modal = ({ children, closeModal, modalTitle }) => {
    const contentRef = useRef();
    useCloseOnOutside(contentRef, closeModal);

    return (
        <div className="modal">
            <div className="modal--content" ref={contentRef}>
                <div className="modal--title">
                    <span className="modal--title-content">{modalTitle}</span>
                    <button className="modal--close" onClick={closeModal}>
                        <i className="ci-close_big" />
                    </button>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;
