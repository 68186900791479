import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import './monthSelector.scss';

const MonthSelector = ({ setTimePeriod, timePeriod, pathName, oldestDate }) => {
    const history = useHistory();

    const monthIsLast = () => {
        const now = moment();
        const selectedMonth = moment(timePeriod);

        return (
            selectedMonth.isSame(now, 'month') &&
            selectedMonth.isSame(now, 'year')
        );
    };

    const monthIsFirst = () => {
        const firstMonth = moment(oldestDate, 'YYYY-MM');
        const selectedMonth = moment(timePeriod);

        return (
            selectedMonth.isSame(firstMonth, 'month') &&
            selectedMonth.isSame(firstMonth, 'year')
        );
    };

    const getTimePeriodLabel = () => {
        return moment(timePeriod).format(`MMM YYYY`);
    };

    const changeTimePeriod = (changeAmount) => {
        const momentTimePeriod = moment(timePeriod);
        const newTimePeriod = momentTimePeriod.add(changeAmount, 'months');

        if (pathName) {
            history.push(`/${pathName}?m=${newTimePeriod.format('YYYY-MM')}`);
        }

        setTimePeriod({
            month: newTimePeriod.month(),
            year: newTimePeriod.year(),
        });
    };

    return (
        <div className="month-selector">
            <button
                className="time-period-control"
                onClick={() => changeTimePeriod(-1)}
                disabled={monthIsFirst()}
            >
                <i className="ci-chevron_big_left" />
            </button>
            <h2>{getTimePeriodLabel()}</h2>
            <button
                className="time-period-control"
                onClick={() => changeTimePeriod(+1)}
                disabled={monthIsLast()}
            >
                <i className="ci-chevron_big_right" />
            </button>
        </div>
    );
};

export default MonthSelector;
