import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import transactionsReducer from './transactions/transactions.reducer';
import budgetReducer from './budget/budget.reducer';
import transactionsPageReducer from './transactionsPage/transactionsPage.reducer';

const reducers = combineReducers({
    transactions: transactionsReducer,
    budget: budgetReducer,
    transactionsPage: transactionsPageReducer,
});

export default createStore(reducers, applyMiddleware(thunk));
