import TransactionFilters from './transactionFilters';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    getBudget,
    getBudgetCategories,
} from '../../modules/budget/budget.selectors';
import { setTransactionFilters } from '../../modules/transactionsPage/transactionsPage.actions';
import { getSelectedFilters } from '../../modules/transactionsPage/transactionsPage.selectors';


const mapStateToProps = (state) => {
    return {
        budget: getBudget(state),
        budgetCategories: getBudgetCategories(state),
        filters: getSelectedFilters(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setTransactionFilters }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionFilters);
