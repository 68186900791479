import Button from '../../components/button';
import PageWrapper from '../../components/pageWrapper';
import React, { useState, useEffect } from 'react';
import './transactions.scss';
import TransactionTable from '../../components/transactionTable';
import TransactionFilters from '../../components/transactionFilters';

const Transactions = ({ adding, setAdding, numSelectedFilters }) => {
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        document.title = 'Transactions - Piglet';
    }, []);

    const renderFiltersButton = () => {
        const buttonProps = {
            className: showFilters ? 'filter-button active' : 'filter-button',
            buttonType: 'secondary',
            disabled: adding,
            onClick: showFilters
                ? () => setShowFilters(false)
                : () => setShowFilters(true),
        };

        return (
            <Button {...buttonProps}>
                {showFilters ? (
                    <i className="ci-filter" />
                ) : (
                    <i className="ci-filter_outline" />
                )}
                Filter{numSelectedFilters ? ` (${numSelectedFilters})` : ''}
            </Button>
        );
    };

    const renderPageHeader = () => {
        return (
            <div className="header">
                <div className="header-left">
                    <h1>Transactions</h1>
                    {renderFiltersButton()}
                </div>
                <div className="header-right">
                    <Button
                        buttonType="primary"
                        disabled={adding}
                        onClick={() => setAdding(true)}
                    >
                        <i className="ci-plus_square" />
                        Add Transaction
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <PageWrapper>
            <div className="transactions">
                {renderPageHeader()}
                {showFilters ? <TransactionFilters /> : null}
                <TransactionTable />
            </div>
        </PageWrapper>
    );
};

export default Transactions;
