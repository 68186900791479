import React, { useState, useEffect } from 'react';
import Button from '../components/button';
import { useAuth } from 'reactfire';
import Logo from '../components/logo';
import './login.scss';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const auth = useAuth();

    useEffect(() => {
        document.title = 'Log in - Piglet';
    }, []);

    const signInWithEmailAndPasswordHandler = (event) => {
        event.preventDefault();

        auth.signInWithEmailAndPassword(email, password)
            .then((user) => {
                window.location.href = '/';
            })
            .catch((error) => {
                setError(error.message);
            });
    };

    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;

        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    return (
        <div className="login">
            <div className="login-form-container">
                <form className="login-form">
                    <div className="login-instructions">
                        <h1>Log in</h1>
                    </div>
                    <span className="login-input">
                        <label htmlFor="email">Email</label>
                        <input
                            name="email"
                            onChange={(event) => onChangeHandler(event)}
                        ></input>
                    </span>
                    <span className="login-input">
                        <label htmlFor="password">Password</label>
                        <input
                            name="password"
                            type="password"
                            onChange={(event) => onChangeHandler(event)}
                        ></input>
                    </span>
                    <Button
                        buttonType="primary"
                        type="submit"
                        onClick={signInWithEmailAndPasswordHandler}
                    >
                        Log in
                    </Button>
                    {error && <p className="error">{error}</p>}
                </form>
            </div>
            <div className="login-art">
                <Logo />
            </div>
        </div>
    );
};

export default Login;
