import React from 'react';
import './button.scss';

const Button = ({ buttonType, onClick, children, className, ...allProps }) => {
    const classes = `button button-${buttonType} ${className ? className : ''}`;

    return (
        <button {...allProps} className={classes} onClick={onClick}>
            <span className="inner" tabIndex="-1">
                {children}
            </span>
        </button>
    );
};

export default Button;
