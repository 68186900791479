import BudgetSheet from '../../components/budgetSheet';
import moment from 'moment';
import PageWrapper from '../../components/pageWrapper';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MonthSelector from '../../components/monthSelector';
import './budget.scss';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Budget = () => {
    const query = useQuery();

    useEffect(() => {
        document.title = 'Budget & Spending - Piglet';
    }, []);

    const getTimePeriod = () => {
        const value = query.get('m');
        const momentValue = value ? moment(value, 'YYYY-MM') : moment();

        return {
            month: momentValue.month(),
            year: momentValue.year(),
        };
    };

    const [timePeriod, setTimePeriod] = useState(getTimePeriod());

    const renderPageHeader = () => {
        return (
            <div className="header">
                <div className="header-left">
                    <h1>Budget &amp; Spending</h1>
                </div>
                <div className="header-right">
                    <MonthSelector
                        timePeriod={timePeriod}
                        setTimePeriod={setTimePeriod}
                        pathName="budget"
                    />
                </div>
            </div>
        );
    };

    return (
        <PageWrapper>
            <div className="budget">
                {renderPageHeader()}
                <BudgetSheet timePeriod={timePeriod} />
            </div>
        </PageWrapper>
    );
};

export default Budget;
